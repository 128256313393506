//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-600:_8606,_9632,_8600,_7704,_2240,_2992,_1980,_2422;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-600')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-600', "_8606,_9632,_8600,_7704,_2240,_2992,_1980,_2422");
        }
      }catch (ex) {
        console.error(ex);
      }