//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-600:_8836,_8004,_6232,_4728,_8704,_4472,_9828,_6200;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-600')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-600', "_8836,_8004,_6232,_4728,_8704,_4472,_9828,_6200");
        }
      }catch (ex) {
        console.error(ex);
      }